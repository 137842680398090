import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeAnim, verticalAnim } from "../Animations";
import useStore from "../Store";
import AnimatedImg from "./AnimatedImg";
import SocialButtons from "./SocialButtons";
import LanguageSelect from "./LanguageSelect";
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import LanguageSelectMobile from "./LanguageSelectMobile";
import { playRandomSound } from "./SoundController";

export default function MainMenu() {
  const setPointer = useStore((state) => state.setPointer);
  const setShowMailPopup = useStore((state) => state.setShowMailPopup);
  const setHasShownMailPopup = useStore((state) => state.setHasShownMailPopup);
  const lang = useStore((state) => state.lang);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  function toggleMobileMenu(val: boolean) {
    setMobileMenuOpen(val);
  }

  return (
    <>
      <div className="hidden lg:flex fixed top-0 left-0 w-full z-50 bg-red-700 border-white border-b-2 justify-between px-4 items-center text-white">
        <MenuLink to="/" nlText="Over Frino" brText="Wa is Frino!?" />
        <MenuLink to="/nieuwsbrief" nlText="Nieuwsbrief" brText="Gezever" />
        <MenuLink to="/optredens" nlText="Optredens" brText="Tetsen vangen" />
        <MenuLink
          to=""
          nlText="Contact"
          brText="Mij nie belle"
          onClick={() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          }}
        />

        <MenuLink
          to=""
          onClick={() => {
            window.open("https://frinogame.netlify.app/");
          }}
          nlText="Speel het Frinospel"
          brText="Spelleke speule"
        />

        <LanguageSelect />
      </div>
      <div
        className={`fixed  lg:hidden bg-red-700 p-2 top-0 right-0 z-50 border-white ${
          mobileMenuOpen ? "" : "border-b border-l"
        }`}
        onClick={() => toggleMobileMenu(!mobileMenuOpen)}
      >
        {mobileMenuOpen ? (
          <IoClose size={30} color="white" />
        ) : (
          <IoMenu size={30} color="white" />
        )}
      </div>
      <LanguageSelectMobile menuOpen={mobileMenuOpen} />
      <div
        className={`w-screen h-screen fixed bg-red-700 left-0 z-40 flex flex-col items-center justify-center transition-all `}
        style={{
          top: mobileMenuOpen ? "0px" : window.innerHeight * -1.3 + "px",
        }}
      >
        <MobileMenuLink
          setMobileMenuOpen={setMobileMenuOpen}
          to="/"
          nlText="Over Frino"
          brText="Wa is Frino!?"
        />
        <MobileMenuLink
          setMobileMenuOpen={setMobileMenuOpen}
          to="/nieuwsbrief"
          nlText="Nieuwsbrief"
          brText="Gezever"
        />
        <MobileMenuLink
          setMobileMenuOpen={setMobileMenuOpen}
          to="/optredens"
          nlText="Optredens"
          brText="Tetsen vangen"
        />
        <MobileMenuLink
          setMobileMenuOpen={setMobileMenuOpen}
          onClick={() => {
            window.open("https://frinogame.netlify.app/");
          }}
          nlText="Speel het Frinospel"
          brText="Spelleke speule"
        />
        <MobileMenuLink
          setMobileMenuOpen={setMobileMenuOpen}
          to=""
          nlText="Contact"
          brText="Mij nie belle"
          onClick={() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
            setMobileMenuOpen(false);
          }}
        />
      </div>
    </>
  );
}

function MenuLink({ to, brText, nlText, onClick }: any) {
  const setPointer = useStore((state) => state.setPointer);
  const lang = useStore((state) => state.lang);

  return onClick ? (
    <span
      onClick={() => {
        playRandomSound();
        onClick();
      }}
      onMouseEnter={() => setPointer(true)}
      onMouseLeave={() => setPointer(false)}
      className="block sm:text-xl lg:text-2xl xl:text-2xl  transform sm:hover:scale-110 active:scale-110 sm:hover:rotate-3"
    >
      {lang === "br" ? brText : nlText}
    </span>
  ) : (
    <Link
      to={to}
      className=""
      onClick={() => {
        window.scrollTo(0, 0);
        playRandomSound();
      }}
    >
      <span
        onClick={onClick}
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        className="block sm:text-xl lg:text-2xl xl:text-2xl  transform sm:hover:scale-110 active:scale-110 sm:hover:rotate-3"
      >
        {lang === "br" ? brText : nlText}
      </span>
    </Link>
  );
}

function MobileMenuLink({
  to,
  brText,
  nlText,
  setMobileMenuOpen,
  onClick,
}: any) {
  const setPointer = useStore((state) => state.setPointer);
  const lang = useStore((state) => state.lang);

  return onClick ? (
    <span
      onClick={() => {
        setMobileMenuOpen(false);
        onClick();
        playRandomSound();
      }}
      onMouseEnter={() => setPointer(true)}
      onMouseLeave={() => setPointer(false)}
      className="block  text-white text-3xl my-4 underline transform sm:hover:scale-110 active:scale-110 "
    >
      {lang === "br" ? brText : nlText}
    </span>
  ) : (
    <Link
      to={to}
      className=""
      onClick={() => {
        setMobileMenuOpen(false);
        playRandomSound();
        window.scrollTo(0, 0);
      }}
    >
      <span
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        className="block  text-white text-3xl my-4 underline transform sm:hover:scale-110 active:scale-110 "
      >
        {lang === "br" ? brText : nlText}
      </span>
    </Link>
  );
}
