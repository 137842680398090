import React from "react";
import useStore from "../Store";
import { playRandomSound } from "./SoundController";

export default function LanguageSelectMobile({ menuOpen }: any) {
  const setLang = useStore((state) => state.setLang);
  const lang = useStore((state) => state.lang);
  const setPointer = useStore((state) => state.setPointer);

  return (
    <div
      className={`lg:hidden py-1  bg-red-700 px-2 top-0 left-0 fixed  flex z-50 ${
        menuOpen ? "" : "border-b border-r border-white"
      }`}
    >
      <img
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        src="/img/brvlag.png"
        className="w-14 h-10"
        onClick={() => {
          setLang("br");
          playRandomSound();
        }}
        style={{
          boxShadow: lang === "br" ? "0px 0px 8px white" : "none",
          border: lang === "br" ? "1px solid white" : "none",
        }}
      />
      <img
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        src="/img/nlvlag.png"
        className="w-14 h-10 ml-2"
        onClick={() => {
          setLang("nl");
          playRandomSound();
        }}
        style={{
          boxShadow: lang === "nl" ? "0px 0px 8px white" : "none",
          border: lang === "nl" ? "1px solid white" : "none",
        }}
      />
    </div>
  );
}
