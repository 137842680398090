import React from "react";
import { Howl } from "howler";
type Props = {};

export default function SoundController({}: Props) {
  return <div>SoundController</div>;
}

export function playRandomSound() {
  SOUNDS[Math.floor(Math.random() * SOUNDS.length)].play();
}

const SOUNDS = [
  new Howl({
    src: ["sound/1.mp3"],
  }),
  new Howl({
    src: ["sound/2.mp3"],
  }),
  new Howl({
    src: ["sound/3.mp3"],
  }),
  new Howl({
    src: ["sound/4.mp3"],
  }),
  new Howl({
    src: ["sound/5.mp3"],
  }),
  new Howl({
    src: ["sound/6.mp3"],
  }),
];
