import React, { useEffect, useRef } from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  useLocation,
} from "react-router-dom";
import MainMenu from "./Components/MainMenu";
import { AnimatePresence } from "framer-motion";
import FrinoShows from "./Components/FrinoShows";
import MouseController from "./Components/MouseController";
import PixiManager from "./Components/PixiManager";
import useStore from "./Store";

import Opteffen from "./Components/Opteffen";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Nieuwsbrief from "./Components/Nieuwsbrief";

/*
const FrinoPlayerContainer = ReactLazyPreload(
  () => import("./Components/FrinoPlayerContainer")
);
*/
function App() {
  const setPointer = useStore((state) => state.setPointer);

  const timerRef = useRef<any>();
  /*
  useEffect(() => {
    FrinoPlayerContainer.preload();
    return () => {};
  }, []);
  */
  const showMailPopup = useStore((state) => state.showMailPopup);
  const showThankYou = useStore((state) => state.showThankYou);

  const setShowMailPopup = useStore((state) => state.setShowMailPopup);

  const location = useLocation();

  function showPopup() {
    setShowMailPopup(true);
  }

  useEffect(() => {
    timerRef.current = setTimeout(showPopup, 5000);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);
  return (
    <div className="w-screen min-h-screen relative">
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <Home />
            {/* <AnimatePresence>
              {showMailPopup && <Mail />}
              {showThankYou && <ThankYou />}
            </AnimatePresence> */}
          </Route>

          <Route path="/optredens">
            <FrinoShows />
          </Route>
          <Route path="/nieuwsbrief">
            <Nieuwsbrief />
          </Route>
          <Route path="/opteffen">
            <Opteffen />
          </Route>
        </Switch>
      </AnimatePresence>
      <MainMenu />
      <PixiManager />
      <Footer />

      {window.innerWidth > 500 && <MouseController />}
    </div>
  );
}

export default App;
