import React from "react";
import useStore from "../Store";
import { playRandomSound } from "./SoundController";

export default function LanguageSelect() {
  const setLang = useStore((state) => state.setLang);
  const lang = useStore((state) => state.lang);
  const setPointer = useStore((state) => state.setPointer);

  return (
    <div className="my-1  w-12 sm:w-40 top-3 left-3 flex  z-50">
      <img
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        src="/img/brvlag.png"
        className="w-1/2 "
        onClick={() => {
          setLang("br");
          playRandomSound();
        }}
        style={{
          boxShadow: lang === "br" ? "0px 0px 8px white" : "none",
          border: lang === "br" ? "1px solid white" : "none",
        }}
      />
      <img
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        src="/img/nlvlag.png"
        className="w-1/2 ml-2"
        onClick={() => {
          setLang("nl");
          playRandomSound();
        }}
        style={{
          boxShadow: lang === "nl" ? "0px 0px 8px white" : "none",
          border: lang === "nl" ? "1px solid white" : "none",
        }}
      />
    </div>
  );
}
