import React, { useState } from "react";
import useStore from "../Store";
import BrVlagRegel from "./BrVlagRegel";

type Props = {};

export default function Footer({}: Props) {
  const setPointer = useStore((state) => state.setPointer);
  const lang = useStore((state) => state.lang);
  const [showMail, setShowMail] = useState(false);

  return (
    <footer className="block w-full relative bg-red-700 text-white  border-white z-30 ">
      <BrVlagRegel />
      <section
        id="Contact"
        className="m-auto px-4 lg:text-xl text-lg  font-frino relative z-20 py-20"
        style={{ maxWidth: "800px" }}
      >
        <img src="/img/frinoLogo_wit.png" className="block w-20 mb-6" />
        {showMail ? (
          <a
            target="_blank"
            className="block underline  "
            onMouseEnter={() => setPointer(true)}
            onMouseLeave={() => setPointer(false)}
            href="mailto:info@frino.nl"
          >
            info@frino.nl
          </a>
        ) : (
          <div
            className="underline"
            onClick={() => {
              setShowMail(true);
            }}
          >
            Email
          </div>
        )}
        <a
          target="_blank"
          className="block underline mt-1"
          onMouseEnter={() => setPointer(true)}
          onMouseLeave={() => setPointer(false)}
          href="https://drive.google.com/drive/folders/1m50UrKPUdTSouXp3JozHF4ZxeWYmpqsh?usp=drive_link"
        >
          Press kit
        </a>
        <a
          target="_blank"
          className="block underline mt-1"
          onMouseEnter={() => setPointer(true)}
          onMouseLeave={() => setPointer(false)}
          href="https://www.youtube.com/@frinouiteindj6233"
        >
          {lang === "br" ? "Brabo's blieke" : "Youtube"}
        </a>
      </section>
      <img
        src="/img/ebike.png"
        className="absolute lg:w-64 w-40 bottom-0  lg:left-1/4 left-4 ebike z-10"
      />
      <img
        src="/img/catharina.png"
        className="absolute right-0 sm:w-40 xl:w-48 w-32 bottom-0 transform z-0"
      />
    </footer>
  );
}
