import React, { useEffect, useState } from "react";

type Props = {};

export default function BrVlagRegel({}: Props) {
  const regels = ["", ""];
  const amt = 60;
  const horArr: any = [];
  for (let i = 0; i < amt; i++) {
    horArr.push("");
  }
  const [grootte, setGrootte] = useState(0);
  useEffect(() => {
    function handleResize() {
      setGrootte(window.innerWidth / amt);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      className="w-screen relative"
      style={{ height: regels.length * grootte }}
    >
      {regels.map((regel, regelIndex) =>
        horArr.map((vierkant: any, index: number) => {
          return (
            <div
              className="absolute"
              style={{
                width: grootte,
                height: grootte,
                top: regelIndex * grootte,
                left: index * grootte,
                backgroundColor:
                  (index + regelIndex) % 2 === 0 ? "#FFFFFF" : "#DD0000",
              }}
            ></div>
          );
        })
      )}
    </div>
  );
}
