import React, { useRef, useState } from "react";
import useStore from "../Store";

type Props = {};

export default function Nieuwsbrief({}: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const lang = useStore((state) => state.lang);
  const [formState, setFormState] = useState("open");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (emailRef.current?.checkValidity()) {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        setFormState("submitting");
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //@ts-ignore
          body: new URLSearchParams(formData).toString(),
        })
          .then(() => {
            //setShowMailPopup(false);
            //setShowThankYou(true);
            console.log("Form successfully submitted");
            setFormState("succes");
          })
          .catch((error) => {
            console.log(error);
            setFormState("error");
          });
      }
    } else {
      console.log("invalid");
    }
  };

  return (
    <div
      className="z-0 relative"
      style={{
        cursor: "none",
      }}
    >
      <div
        className="w-screen bg-cover z-10 text-black relative  overflow-hidden font-frino flex justify-center items-center"
        style={{ minHeight: "95vh" }}
      >
        <div
          className=" text-center my-32 z-10 relative"
          style={{ maxWidth: "800px" }}
        >
          {formState === "open" && (
            <>
              <h2 className="md:text-4xl xl:text-6xl text-2xl">Nieuwsbrief</h2>
              <div className="text-lg md:text-xl xl:text-2xl leading-relaxed tracking-wider">
                {lang === "br"
                  ? `Wilde gij ok als eurste haarfijn weten wa wij bij Frino allemaal
            uitspoken? Zo van da ge elke maand tege Sjon van hierneffe kan
            bakkesen over wa ge nou weer hed gelezen? Schrijft oe eige dan héul
            gou in veur d'n enige echte Frino nieuwsbrief!`
                  : `Wil jij op de hoogte blijven van aankomende Frino optredens, als eerste nieuwe nummers horen en weten waar we allemaal mee bezig zijn? Schrijf je dan nu in voor de Frino-nieuwsbrief!`}
              </div>
              <form
                name="emailform"
                data-netlify="true"
                ref={formRef}
                method="post"
                onSubmit={handleSubmit}
              >
                <input
                  ref={emailRef}
                  name="email"
                  type="email"
                  required
                  className="text-black font-frino p-2 mt-6 spaced sm:w-80"
                ></input>
                <input type="hidden" name="form-name" value="emailform" />
                <input name="bot-field" type="text" hidden></input>
                <div className="w-full flex flex-col items-center mt-4">
                  <button
                    type="submit"
                    className="font-frino border-white border-2 p-2 rounded-md block text-xl spaced bg-red-700 text-white transform hover:scale-110 hover:rotate-3 transition-transform duration-150"
                  >
                    {lang === "br" ? "Kom maar door!" : "Hou mij op de hoogte"}
                  </button>
                </div>
              </form>
            </>
          )}
          {formState === "submitting" && (
            <>
              <h2 className="md:text-3xl xl:text-5xl text-2xl">
                Ekkes wachten...
              </h2>
            </>
          )}
          {formState === "succes" && (
            <>
              <h2 className="md:text-3xl xl:text-5xl text-2xl">
                {lang === "br" ? `Joe.` : `Gelukt.`}
              </h2>
              <div className="text-lg md:text-xl xl:text-2xl leading-relaxed tracking-wider">
                {lang === "br"
                  ? `Ge heurt nog van ons!`
                  : `Je hoort snel van ons!`}
              </div>
            </>
          )}
          {formState === "error" && (
            <>
              <h2 className="md:text-3xl xl:text-5xl text-2xl">
                {lang === "br" ? `Godnondeju.` : `Verdorie.`}
              </h2>
              <div className="text-lg md:text-xl xl:text-2xl leading-relaxed tracking-wider">
                {lang === "br"
                  ? `Tis nie gelukt, probeert et later anders nog ekkes.`
                  : `Er is iets misgegaan, probeer het later nog eens.`}
              </div>
            </>
          )}
        </div>
        <img
          src="/img/rimpelhond.png"
          className="block xl:w-60 w-32 lg:w-48 absolute  bottom-0 left-0  z-0"
        />
        <img
          src="/img/koenOpteffen.png"
          className="block xl:w-40 w-12 lg:w-20 absolute  -bottom-6 right-12 xl:right-24  z-10"
        />
        <img
          src="/img/rubenOpteffen.png"
          className="block xl:w-48 w-14 lg:w-24 absolute   -bottom-6 right-0  z-0"
        />
        <img
          src="/img/ronnie.png"
          className="block xl:w-80 w-36 lg:w-60 absolute  bottom-0 -right-8 -top-0 transform z-0"
          style={{ transform: "rotate(-135deg" }}
        />
      </div>
    </div>
  );
}
