import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeAnim, verticalAnim } from "../Animations";
import useStore from "../Store";
import AnimatedImg from "./AnimatedImg";
import SocialButtons from "./SocialButtons";
import BrVlagRegel from "./BrVlagRegel";

export default function Home() {
  const setPointer = useStore((state) => state.setPointer);
  const setShowMailPopup = useStore((state) => state.setShowMailPopup);
  const setHasShownMailPopup = useStore((state) => state.setHasShownMailPopup);
  const lang = useStore((state) => state.lang);
  const [showMail, setShowMail] = useState(false);

  return (
    <div
      className="z-0 relative"
      style={{
        cursor: "none",
      }}
    >
      <div
        className="w-screen bg-cover z-0 text-black relative  overflow-hidden"
        style={{ minHeight: "95vh" }}
      >
        <div className="absolute w-full px-8 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-8 z-50 flex flex-col items-center">
          <img src="/img/frinologo.png" className="xl:w-96 w-32 md:w-48" />
          <div>
            <span className="xl:text-5xl text-xl md:text-2xl  block">
              {lang === "br"
                ? "Als di nog poëzie is, wit ik het ok niet meer"
                : "Als dit nog poëzie is, weet ik het ook niet meer"}
            </span>
            <span className="md:text-2xl text-lg block">- ons mam</span>
          </div>
          <div className=" font-frino  text-center xl:text-2xl md:text-xl text-xl md:mt-10 mt-6 bg-red-600 py-2 md:px-6 px-2 border border-white text-white  transform sm:hover:scale-110 sm:hover:rotate-3 transition-transform duration-100 pulseShadow">
            <Link
              to="/nieuwsbrief"
              onMouseEnter={() => setPointer(true)}
              onMouseLeave={() => setPointer(false)}
              className="block"
            >
              {lang === "br"
                ? "Schrijft oe eigenste in veur d'n Brabo nieuwsbrief"
                : "Schrijf je in voor de Frino-nieuwsbrief"}
            </Link>
          </div>{" "}
        </div>
        <img
          src="/img/koenuitgeknipt.png"
          className="sm:w-32 lg:w-52 w-24 absolute -bottom-8 -right-3 rotateAnim rotateAnim1 origin-bottom-right z-20"
        />
        <img
          src="/img/rubenuitgeknipt.png"
          className="sm:w-36 lg:w-56 w-24 absolute -bottom-8 -left-2 rotateAnim rotateAnim2 origin-bottom-left z-40"
        />
        <img
          src="/img/verrekijker.png"
          className="w-36 lg:w-72 xl:w-80  absolute bottom-0 right-6 lg:right-20 verrekijkerAnim  z-10"
        />
        <img
          src="/img/brabantvlag.png"
          className="sm:w-60 lg:w-80 xl:w-84 w-40 absolute top-14 xl:bottom-96 md:top-32  right-0 z-0 sm:opacity-100 opacity-40"
          style={{ transform: "rotate(90deg) rotateX(180deg)" }}
        />
        <img
          src="/img/evoluon.png"
          className="hidden lg:block lg:w-96 w-44 absolute sm:-bottom-4 -bottom-1 sm:right-20 right-4  z-10"
        />
        <img
          src="/img/vlaggetjes.png"
          className="lg:h-52 sm:h-32 h-20 absolute sm:-top-4 top-0 -left-24  z-0 transform -rotate-45"
        />
        <div className="absolute -left-4 w-56 lg:w-80 lg:left-32 sm:w-64 sm:left-14 bottom-40 sm:bottom-60 z-20">
          <AnimatedImg
            active
            speed={500}
            imgArr={[
              "/img/tijger.png",
              "/img/tijger.png",
              "/img/tijger.png",
              "/img/tijger.png",
              "/img/tijgerknipper.png",
              "/img/tijger.png",
              "/img/tijgerknipper.png",
            ]}
          />
        </div>
      </div>
      <BrVlagRegel />
      <div className="bg-black relative z-10">
        <div
          className="text-white font-frino leading-relaxed text-lg lg:text-xl xl:text-2xl px-4  pb-20 lg:pb-10 tracking-widest m-auto"
          style={{ maxWidth: "800px" }}
        >
          {/* <img src="/img/scootmobiel.png" className="w-20 opEnNeer" /> */}
          <img
            src="/img/Frinofoto_transparant.png"
            className="w-full md:-mb-8 -translate-y-16 -mb-14 transform md:-translate-y-28"
          />
          {lang === "br" ? (
            <>
              <div className="xl:text-5xl lg:text-2xl text-xl text-red-400">
                Witte wa pas skon is?
              </div>
              <div className="mt-4">
                Da ut zonnetje skijnt en da alles opgevoerd is: oew tuinstoel,
                oew Ford Panda en oew Thysco-stick mè saté van om de hoek.
                Jonge, doar kenne wij zo van geniete. En dan, als ge oew ogen
                sluit, heurde ut: de vogeltjes dè fluiten in onvervalste
                Brabo-hop. Over oud-PSV-ers die stage liepen bij Jackie Chan,
                over Betsy de marmerkat, over Ronnie die zijn eigenste rug nie
                ken oanroake zonder oan den Sovjet-Unie te denke. Moar er waait
                nen neie wind, nen wind die neie blauwbekpapagaaien,
                witgoedkraaien of wittikutallemoaline veur exotisch over den
                provinciale grenzen neerkwakkelt.
              </div>
              <div className="mt-8  text-red-400">
                Dus ik zeg tege ons Melis:
              </div>
              <div className="xl:text-3xl lg:text-2xl text-xl mt-8 xl:mb-8 mb-244">
                Vrouw, der komt nen neie veurstelling oan. En wel in den zomer
                2025. Godverdomme, dat wor pas echt skon!
              </div>
            </>
          ) : (
            <>
              <div className="">
                Vanuit hun plastic tuinstoel behandelt het Eindhovense
                brabohopcollectief Frino de grindtegelfilosofie des levens.
              </div>
              <div className="my-8 xl:text-6xl text-4xl rotateAnim3 inline-block">
                Wa?!
              </div>
              <div className="">
                Ja, dat is muziektheater, Brabantse hiphop en een kroegverhaal
                in een. En dat allemaal in de taal van de paradijsvogels waar
                zij zo van houden. Zoals van een oud-PSV-er die stage liep bij
                Jackie Chan, Betsy de marmerkat, en een bodybuilder die zijn
                onderrug niet meer kan aanraken zonder aan de Sovjet-Unie te
                denken. Momenteel werken zij aan hun nieuwe show Kuilkwark
                (zomer 2025). En mocht je nu niet uit het zuiden komen en vrezen
                dat je er niks van snapt, weet dan:
              </div>
              <div className="xl:text-4xl lg:text-2xl text-xl text-red-400 mt-8 xl:mb-0 mb-24">
                'Je verstaat er geen klote van, maar je begrijpt het wel.'
              </div>
            </>
          )}
        </div>
        <div
          className={`absolute left-8  w-40 h-40 lg:w-64 lg:h-64 bottom-2 lg:-bottom-16 transform transition-transform duration-500 $
       translate-y-0 upAndDown
      `}
        >
          <AnimatedImg
            imgArr={["/img/jackie1.png", "/img/jackie2.png"]}
            active={true}
            speed={400}
          />
        </div>
      </div>
    </div>
  );
}
